import React from "react";
import { useEffect,  } from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-scroll";
import Observer from 'tailwindcss-intersect';
import HTML from "../assets/html.png";
import CSS from "../assets/css.png";
import JavaScript from "../assets/javascript.png";
import ReactImg from "../assets/react.png";
import Node from "../assets/node.png";
import AWS from "../assets/aws.png";
import GitHub from "../assets/github.png";
import Tailwind from "../assets/tailwind.png";
import Mongo from "../assets/mongo.png";
import Py from "../assets/python.svg";
import Dock from "../assets/docker.svg";
import Django from "../assets/django.svg";
import Firebase from "../assets/firebase.png";
import Redux from "../assets/redux.svg";
import GraphQL from "../assets/graphql.svg";
import Neo4j from "../assets/neo4j.svg";
import C from "../assets/CLANG.png"
import AI from "../assets/adobe_illustrator.svg"

const skillList = [
  {
    title: "HTML",
    img: HTML,
  },
  {
    title: "CSS",
    img: CSS,
  },
  {
    title: "JAVASCRIPT",
    img: JavaScript,
  },
  {
    title: "REACT",
    img: ReactImg,
  },
  {
    title: "PYTHON",
    img: Py,
  },
  {
    title: "C#",
    img: C,
  },
  {
    title: "NODE JS",
    img: Node,
  },
  {
    title: "MONGO",
    img: Mongo,
  },
  {
    title: "GRAPHQL",
    img: GraphQL,
  },
  {
    title: "NEO4J",
    img: Neo4j,
  },
  {
    title: "AWS",
    img: AWS,
  },
  {
    title: "REDUX",
    img: Redux,
  },
  {
    title: "DOCKER",
    img: Dock,
  },
  {
    title: "DJANGO",
    img: Django,
  },
  {
    title: "FIREBASE",
    img: Firebase,
  },
  {
    title: "ILLUSTRATOR",
    img: AI,
  },
];

const Skills = () => {
  
  Observer.start();

  useEffect(()=>{},[])
  
  return (
    <div name="skills" className="w-full text-gray-300 pb-[200px]">
      {/* Container */}
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div>
          <p className="text-4xl font-bold inline border-b-4 border-pink-600 ">
            Skills
          </p>
          <p className="py-4">These are the technologies I've worked with</p>
          <Link to="work" smooth={true} duration={500}>
            <button className="text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-pink-600 hover:border-pink-600">
              View Work
              <span className="group-hover:rotate-90 duration-300">
                <HiArrowNarrowRight className="ml-3 " />
              </span>
            </button>
          </Link>
        </div>

        <div
          className="w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8"
        >
          {skillList.map((skill, index) => {
            let css=""
        
            return (
              <div
                key={index}
                className={` shadow-md flex flex-col shadow-[#040c16] hover:scale-110 hover:shadow-2xl hover:shadow-white duration-500 transition-color`}
              >
                <img
                  className="w-20 mx-auto mt-6"
                  src={skill.img}
                  alt="HTML icon"
                />
                <p className="my-4">{skill.title}</p>
              </div>
            );
          })}

        </div>
      </div>
    </div>
  );
};

export default Skills;
