import About from "./components/About";
import Contact from "./components/Contact";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Skills from "./components/Skills";
import Work from "./components/Work";

function App() {
  return (
    <div className="min-h-screen" style={{backgroundColor:'#2F2F2F'}}>
      <div className="bg-star-minimal">
        <Navbar />
        <Home />
        <Skills />
        <Work />
        <Contact />
      </div>
    </div>
  );
}

export default App;
