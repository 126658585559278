import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Helmet } from "react-helmet";
import Favicon from "./assets/favicon_io/favicon.ico"

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <title>Brictoni Piper Portfolio</title>
      <link rel="icon" href={Favicon} />
    </Helmet>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
