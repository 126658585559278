import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-scroll";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";

const Home = () => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.5,
  });

  const [css, setCss] = useState("");
  const [opacity, setOpacity] = useState("");
  const [big, setBig] = useState("");
  useEffect(() => {
    if (inView) {
      setCss("opacity-1 translate-x-0");
      setOpacity("opacity-0.5")
      setBig("opacity-1 translate-x-0")
      console.log("home",inView)
    } else {
      setCss("opacity-0 translate-x-1/4 blur")
      setOpacity("opacity-0")
      setBig("opacity-1 translate-x-1/2 blur")
      console.log("home",inView)
    }
  }, [inView]);

  return (
    <div ref={ref} name="home" className={`w-full h-screen pb-[200px]`}>
      {/* Container */}
      <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full">
        <p className={`text-pink-600 ease-in duration-700 ${css}`}>Hi, my name is</p>
        <h1 className={`text-4xl sm:text-7xl font-bold duration-[1000ms] text-[#ccd6f6] ${css}`}>
          Brictoni Piper
        </h1>
        <h2 className={`text-4xl sm:text-7xl font-bold text-[#8892b0] duration-[1300ms] ${big}`}>
          I'm a Full Stack Developer.
        </h2>
        <p className={`text-[#ccd6f6] py-4 max-w-[700px] transition ease-in duration-[1800ms] ${opacity}` }>
          I’m a full-stack developer specializing in building (and occasionally
          designing) exceptional digital experiences. Currently, I’m focused on
          building responsive full-stack web applications.
        </p>
        <div>
          <Link to="skills" smooth={true} duration={500}>
            <button className="text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-pink-600 hover:border-pink-600">
              View Skills
              <span className="group-hover:rotate-90 duration-300">
                <HiArrowNarrowRight className="ml-3 " />
              </span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
