import React from "react";
import WorkImg from "../assets/workImg.jpeg";
import realEstate from "../assets/realestate.jpg";
import Reddit from "../assets/redditdemo.png";
import Uber from "../assets/uber.png";
import Skincare from "../assets/gopicky.png";
import Flask from "../assets/Flask.png";
import Amazon from "../assets/Amazon.png";
import Medium from "../assets/Medium.png";
import Habit from "../assets/HabitNew.png";

const work = [
  {
    title: "Product Review",
    demo: "https://skincare-website.vercel.app/",
    img: Skincare,
  },
  {
    title: "HabiTracker",
    demo: "https://habitracker.me/",
    img: Habit,
  },
  {
    title: "Blog",
    demo: "https://sanitity-demo-medium.vercel.app/",
    img: Medium,
  },
  {
    title: "Food Delibery",
    code: "https://github.com/Janon000/uber-eats",
    img: Uber,
  },
  {
    title: "Ecommerce",
    demo: "https://amazon-clone-rho-three.vercel.app/",
    img: Amazon,
  },
  {
    title: "Social Media",
    demo: "https://yt-reddit-2-two.vercel.app/",
    img: Reddit,
  }
];

const Work = () => {
  return (
    <div name="work" className="w-full text-gray-300 pb-[200px]">
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 text-gray-300 border-pink-600">
            Projects
          </p>
          <p className="py-6">Check out some of my recent work</p>
        </div>

        {/* Container */}
        <div className="grid sm:grid-cols-2 md:grid-cols-2 gap-4">
          {work.map((item) => (
            <div
              style={{ backgroundImage: `url(${item.img})` }}
              className="shadow-lg shadow-[#040c16] group container flex justify-center items-center mx-auto content-div"
            >
              {/* Hover Effects */}
              <div className="opacity-0 group-hover:opacity-100">
                <div className="flex justify-center">
                  <span className="text-2xl font-bold text-white tracking-wider">
                    {item.title}
                  </span>
                </div>
                <div className="pt-8 text-center">
                  {item.demo ? (
                    <a href={item.demo}>
                      <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                        Demo
                      </button>
                    </a>
                  ) : (
                    <></>
                  )}
                  {item.code ? (
                    <a href={item.code}>
                      <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                        Code
                      </button>
                    </a>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Work;
